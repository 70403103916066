@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.isFilterHidden {
  height: 45px;
  overflow: hidden;
  position: relative;
  top: 0px;
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    margin-bottom: 20px;
    align-items: center;
  }
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.mainPanelContent {
  display: flex;
}

.filters {
  margin-left: auto;
}

.resultsFound {
  white-space: nowrap;
}

.toggleFilterButton {
  display: flex;
  margin-left: auto;

  align-items: center;

  width: fit-content;
  height: fit-content;
  min-height: 40px;
  padding: 0px 20px;

  border: none;
  background-color: transparent;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:hover {
    color: var(--marketplaceColor);
  }
}

.sortyByWrapper {
  display: inline-flex;
  margin-bottom: 12px;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}
